import { FeatureFlagContext } from "./context/FeatureFlagContext";
import React, { StrictMode, useEffect, useState } from "react";
import { loadStdlib } from '@reach-sh/stdlib'
import MyAlgoConnect from '@reach-sh/stdlib/ALGO_MyAlgoConnect';
import WalletConnect from '@reach-sh/stdlib/ALGO_WalletConnect';
import {
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";
import Home from './pages/Home'
import Sell from './pages/Sell'
import Auction from './pages/Auction'
import Faq from './pages/Faq'
import Browse from './pages/Browse'
import Navbar from './components/Navbar'
import Container from 'react-bootstrap/Container'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import './App.css';
//import useGaTracker from './hooks/useGaTracker'
import queryString from 'query-string';
import md5 from 'blueimp-md5'

const axios = require('axios');

const APP_ID_BANK = 27856254
const ASSET_ID_JAM = 27735901
const versions = [
  ['jam-0.6.6', {TestNet: 18115083, MainNet: 17644090}, 'ec87453114c5633b212691c4c594e894'], // add touch, lockout eoa bid
  ['jam-0.6.4', {TestNet: 18094956, MainNet: 17633763}, 'a6641732e55cafe5c247d8f5cd67fd7e'], // add buy after close
  //['jam-0.6.4b', {TestNet: 18026946, MainNet: 17523556}, 'a90de1eefdb4f069570d283fbf8cd249'], // add buy after close
  //['jam-0.6.4a', {TestNet: 18026946, MainNet: 17523556}, 'c9fd85a3880610b947b7b5f3665fa103'], // add buy after close
  //['jam-0.6.3', {TestNet: 18026946, MainNet: 17523556}, '29afe727aa336c0b8833ae2da7137440'], // fixed hot potato reserve issue
  //['jam-0.6.2', {TestNet: 17847659, MainNet: 17494930}, '2aa4a5ac4728c11ec05186b4cb88f688'], // same as v0.6.1 but using PROD line to close after dealing
  //['jam-0.6.1', {TestNet: 17847659, MainNet: 17486193},'56b3919f33ff8bb90d89a764823b03e9'], // add min bid, close at any time
  //['jam-0.6.0', {TestNet: 17847659, MainNet: 17486193}, '5732606a2d533a3780aa28142baffcca'], // prior
  //['jam-0.5.0', {TestNet: 17847659, MainNet: 17486193}, '1eaa430a28ea0fbba42edd721c12dbc7'], // prior
]
const [appVersion, firstBlock, approvalProgram] = versions[0]
const [
  ADDR_PLATFORM_DEFAULT,
  ADDR_PLATFORM_DISCOVERY,
  ADDR_PLATFORM_DISCOVERY2,
  ADDR_PLATFORM_SHARED,
  ADDR_PLATFORM_PRIVATE,
] = [
    '7GDBWS6G5YB5TS4SYTY5IRKHYR5G7JZIAAIGNNSV3VFQJ7MOA3EZZ3QLFI',
    'J5UB2NODM4GLI5ID2VYPT6DT6D2CRGJBCRCDYA67SC2754SZTH5R7FXRF4',
    'EWHAS2U34VKLIVTWLJ4VOIXI2GTH5IIHVSTUNO52UPYOKTEAXFO7OPLHFE',
    'WDJSPRRCX54EST6HHLPZEJD36JR456VO6U3W7647TDIFET72LS6FHAREIA',
    'ZM62PGOING7KM3UOXJJDRA3IDOYWC6RYAX2JBCF7PPZZXNCO6XLU3KR4WY',
  ]

// eslint-disable-next-line no-unused-vars
const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let algoexplorerapi_endpoint
if(providerEnv === "MainNet") {
  algoexplorerapi_endpoint = 'https://algoexplorerapi.io'
} else {
  algoexplorerapi_endpoint = 'https://testnet.algoexplorerapi.io'
}

const stdlib = loadStdlib(REACT_APP_NETWORK)
// ALGO 
if (REACT_APP_NETWORK === 'ALGO') {
  if(!localStorage.getItem('walletFallback')) {
    localStorage.setItem('walletFallback', 'Mnemonic')
  }
  if(!localStorage.getItem('settingsWalletFallback')) {
    localStorage.setItem('settingsWalletFallback', 'MyAlgoConnect')
  }
  if (localStorage.getItem('walletFallback') === "MyAlgoConnect") {
    stdlib.setWalletFallback(stdlib.walletFallback({
      providerEnv, MyAlgoConnect
    }));
  }
  else if (localStorage.getItem('walletFallback') === "WalletConnect") {
    stdlib.setWalletFallback(stdlib.walletFallback({
      providerEnv, WalletConnect
    }));
  } else {
    stdlib.setWalletFallback(stdlib.walletFallback({
      providerEnv
    }));
  }
}

function App(props) {
  const WAValidator = require('@swyftx/api-crypto-address-validator')
  let history = useHistory()
  let location = useLocation()
  const { url } = queryString.parse(location?.search)
  if (url) {
    let [path, id] = url.split('/').slice(1)
    switch (path) {
      case 'browse':
      case 'faq':
        history.push(`/${path}`)
        break
      case 'sell':
      case 'auction':
        if (!isNaN(parseInt(id)) || WAValidator.validate(id, 'algo')) {
          history.push(`/${path}/${id}`)
        } else {
          history.push(`/${path}`)
        }
        break
      default:
        break
    }
  }
  //useGaTracker()
  const [state, setState] = useState({
    APP_ID_BANK,
    ASSET_ID_JAM,
    ADDR_MNEMONIC: ADDR_PLATFORM_SHARED,
    ADDR_PLATFORM: ADDR_PLATFORM_DEFAULT,
    ADDR_DISCOVERY: ADDR_PLATFORM_DISCOVERY,
    ADDR_DISCOVERY2: ADDR_PLATFORM_DISCOVERY2,
    ADDR_PRIVATE: ADDR_PLATFORM_PRIVATE,
    once: false,
    acc: null,
    method: localStorage.getItem("settingsWalletFallback") || "Mnemonic",
    stdlib,
    using: "MyAlgoConnect",
    approvalProgram,
    firstBlock: firstBlock[providerEnv],
    //versions
  })
  const connectAccountUsingLocalStorage = async () => {
    let acc = null
    try {
      let addr = localStorage.getItem('addr')
      acc = await stdlib.connectAccount({ addr })
    } catch (e) {
      console.log({ e })
    }
    return acc
  }
  const connectAccountUsingDefaultAccount = async () => {
    let acc = null
    try {
      acc = await stdlib.getDefaultAccount()
      console.log({ acc })
      localStorage.setItem('addr', acc.networkAccount.addr)
    } catch (e) {
      console.log({ e })
      console.log(4)
    }
    return acc
  }
  const connectAccount = async (method = "Default") => {
    console.log("Getting account ...")
    console.log({ method })
    let acc
    console.log(2)
    switch (method) {
      case 'Mnemonic':
        acc = await stdlib.newAccountFromMnemonic("prize cube acquire asset thing pipe school craft story enforce tunnel hand fun forget tonight boss pink thank force orchard flat invest rule absent plate")
        break
      case 'LocalStorage':
        acc = await connectAccountUsingLocalStorage()
        break
      case 'Default':
      default:
        acc = await connectAccountUsingDefaultAccount()
    }
    console.log(3)
    if (!acc) return
    console.log({ acc })
    let accInfo = (await axios.get(`${algoexplorerapi_endpoint}/v2/accounts/${acc?.networkAccount?.addr}`))?.data
    /* assetInfo */
    let assetInfo = (await Promise.all([
      ...accInfo?.assets?.filter(el => el.amount > 0)?.map(el =>
        axios.get(`${algoexplorerapi_endpoint}/idx2/v2/assets/${el["asset-id"]}`))
    ])).map(el => ({ ...el, ...(el?.data?.asset) }))
    /* appInfo */
    let appInfo
    /*
    let approvalPrograms = versions.map(([,,approvalProgram]) => approvalProgram)
    console.log({approvalPrograms})
    let appInfo = accInfo['created-apps'].filter(el => {
      let hash = md5(el?.params['approval-program'])
      console.log(hash)
      return approvalPrograms.includes(hash)
    })
    */
    appInfo = accInfo['created-apps'].filter(el => {
      let hash = md5(el?.params['approval-program'])
      return approvalProgram === hash
    })
    return {
      ...acc,
      ...accInfo,
      assetInfo,
      appInfo
    }
  }
  const disconnectAccount = () => {
    localStorage.removeItem('addr')
    localStorage.setItem('walletFallback', 'Mnemonic')
    history.go('/')
    //setState({...state, acc: null})
  }
  useEffect(() => {
    if (!state.once) {
      (async () => {
        window.prompt = () => { } // prevent acc2 prompt for account address
        setState({
          ...state,
          once: true,
          acc: await connectAccount(state.method)
        })
      })();
    }
  })
  const handleConnect = async () => {
    localStorage.setItem("walletFallback",
      localStorage.getItem('settingsWalletFallback') || "MyAlgoConnect")
    history.go()
    //let acc = await connectAccount()
    //setState({ ...state, acc, using: "asfd" })
  }
  const containerStyle =
  {
    "alignItems": "baseline",
    "display": "flex",
    "justifyContent": "center",
    "height": "100%",
  }
  return <FeatureFlagContext.Consumer>
    {({
      browsePage,
      sellPage
    }) =>
    <LocalizationProvider dateAdapter={DateAdapter}>
      <span style={{color: "white", padding: "5px"}}>{providerEnv}</span>
      <div className="App">
        <Navbar
          {...state}
          handleConnect={handleConnect}
          handleDisconnect={disconnectAccount} />
        <Container
          style={containerStyle}
          size="lg"
          fluid="lg">
          <Switch>
            <Route exact path="/auction">
              <Auction {...state} />
            </Route>
            <Route path="/auction/:appId">
              <Auction {...state} />
            </Route>
            {sellPage &&
              <Route exact path="/sell">
              <Sell {...state} />
            </Route>}
            {sellPage && <Route path="/sell/:appId">
              <Sell {...state} />
            </Route>}
            {browsePage && <Route exact path="/browse">
              <Browse {...state} />
            </Route>}
            <Route exact path="/faq">
              <Faq />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Container>
      </div>
    </LocalizationProvider>}
  </FeatureFlagContext.Consumer>
}

export default App;